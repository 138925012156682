<!-- //科技管理 -->
<template>
  <div>
    <div>
      <el-descriptions :title="plandDetail.name" border>
        <el-descriptions-item label="亮点">{{ plandDetail.shortTitle || '-' }}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{ plandDetail.createTime || '-' }}</el-descriptions-item>
        <el-descriptions-item label="种植作物">{{ plandDetail.cropsACategoryName || '-' }}</el-descriptions-item>
        <el-descriptions-item label="作业亩产"
          >{{ plandDetail.cropsAPredictYield || '-' }}{{ plandDetail.cropsAPredictYield ? '斤' : '' }}</el-descriptions-item
        >
        <el-descriptions-item label="积温要求"> {{ plandDetail.accumulatedTemp + '℃' || '-' }}</el-descriptions-item>
        <el-descriptions-item label="关联种植季">{{ getSeasonDetail(plandDetail.seasonId) || '-' }}</el-descriptions-item>
        <el-descriptions-item label="状态">{{ geetStatus(plandDetail.state) || '-' }}</el-descriptions-item>
      </el-descriptions>
    </div>
    <el-row type="flex" justify="end" style="margin-bottom: 12px">
      <el-button type="primary" size="small" @click="(isEdit = false), (dialogVisible = true)">选地块</el-button>
    </el-row>

    <el-table :data="tableData" stripe style="width: 100%" header-cell-class-name="header-cell-bg" header-row-class-name="header-bg">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <el-table-column prop="name" label="地块名称"> </el-table-column>
      <el-table-column label="地块位置">
        <template slot-scope="scope">
          {{ scope.row.province }}{{ scope.row.city }}{{ scope.row.county }}{{ scope.row.township }}{{ scope.row.village }}{{ scope.row.address }}
        </template>
      </el-table-column>
      <el-table-column prop="ownerName" label="负责人"> </el-table-column>
      <el-table-column prop="name" label="面积">
        <template slot-scope="scope">{{ formatPlantArea(scope.row.area) }}亩 </template>
      </el-table-column>
      <el-table-column prop="plantTaskNums" label="任务数量"> </el-table-column>
      <el-table-column prop="plantTaskIgnoredNums" label="已忽略任务数量"> </el-table-column>

      <el-table-column prop="plantTaskCompleteNums" label="已完成"> </el-table-column>

      <!-- <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="toEdit(scope.row)">编辑</el-button>
          <el-popconfirm title="确认删除?" @confirm="toDelete(scope.row.id)">
            <el-button slot="reference" type="text" size="small">删除</el-button>
          </el-popconfirm>
        </template>
                                        </el-table-column> -->
    </el-table>

    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
    <selectLand :dialogVisible.sync="dialogVisible" @updateList="getList"> </selectLand>
  </div>
</template>

<script>
import selectLand from '../../../components/selectLand.vue';
import { schemeLandList, getRequest, seasonList } from '@/api/api.js';
import * as utils from '@/utils/utils.js';
import dayjs from 'dayjs';
export default {
  components: {
    selectLand
  },
  data() {
    return {
      plandDetail: {},
      currentItem: {},
      dialogVisible: false,
      isEdit: false,
      title: '',
      applytDate: [],
      startDate: [],
      currentId: '',
      contractName: '',
      searchParams: {},
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      tableData: [],
      season: []
    };
  },
  created() {},
  mounted() {
    this.getList();
    this.getSeason();
  },
  methods: {
    formatPlantArea(val) {
      return utils.formatArea(val);
    },
    geetStatus(state) {
      // 1待启用、2可用、3不可用、4已删除
      let val = '';
      switch (state) {
        case 1:
          val = '待启用';
          break;
        case 2:
          val = '可用';
          break;
        case 3:
          val = '不可用';
          break;
        case 4:
          val = '已删除';
          break;
        default:
          break;
      }
      return val;
    },
    getSeasonDetail(key) {
      let val = '';
      this.season.forEach((e) => {
        //console.log(e);
        if (e.id == key) {
          //console.log(e.name);
          val = e.name;
        }
      });
      return val;
    },
    getSeason() {
      seasonList()
        .then((res) => {
          this.season = res;
          this.getDetail();
        })
        .catch((error) => {});
    },
    getDetail() {
      getRequest('/api/operate/planting/scheme/view', { id: this.$route.query.id })
        .then((res) => {
          this.plandDetail = res;
        })
        .catch();
    },
    toDetail(item) {
      // this.$router.push({ name: 'detailService', query: { id: item.id } })
    },
    search() {
      this.searchParams = {
        title: this.title,
        contractName: this.contractName
      };
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    getList() {
      schemeLandList({
        plantingSchemeId: this.$route.query.id,
        pageNum: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
        ...this.searchParams,
        withoutPlantingScheme: 1
      })
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span1 {
    padding-right: 24px;
    font-size: inherit;
    font-size: 12px;
  }
}
</style>
