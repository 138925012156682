<template>
  <div>
    <el-row>
      <el-col :span="6" class="flex-row">
        <span class="span1">专家姓名</span>
        <el-input v-model="expertName" style="width: 60%" placeholder="请输入内容" clearable></el-input>
      </el-col>
      <el-col :span="6" class="flex-row">
        <span class="span1">问题类型</span>
        <el-select v-model="questionType" placeholder="请选择" clearable>
          <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="flex-row">
        <span class="span1">提问时间</span>
        <el-date-picker
          style="width: 60%"
          v-model="applytDate"
          type="datetimerange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
        >
        </el-date-picker>
      </el-col>
      <el-col :span="6" class="flex-row">
        <el-button type="primary" @click="search">搜索</el-button>
      </el-col>
    </el-row>

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="未录入答案" name="1"></el-tab-pane>
      <el-tab-pane label="已录入答案" name="2"></el-tab-pane>
    </el-tabs>

    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      header-cell-class-name="header-cell-bg"
      header-row-class-name="header-bg"
      @cell-click="onCellClick"
    >
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <el-table-column prop="title" label="标题"> </el-table-column>
      <el-table-column prop="questionType" label="问题类型">
        <template slot-scope="scope">
          {{ getQuestionType(scope.row.questionType) }}
        </template>
      </el-table-column>
      <el-table-column prop="userName" label="提问人"> </el-table-column>
      <el-table-column prop="questionTime" label="提问时间"> </el-table-column>
      <el-table-column prop="expertName" label="要求回答专家"> </el-table-column>
      <el-table-column label="附件">
        <template slot-scope="scope">
          <el-popover v-if="scope.row.attachments" placement="right" trigger="click">
            <el-table :data="scope.row.attachments">
              <el-table-column width="200" property="title" label="文件名"></el-table-column>
              <el-table-column width="100" label="操作">
                <template slot-scope="scope2">
                  <el-button type="text" size="small" slot="reference" @click="downLoadFile(scope2.row.imgUrl)">下载</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-button type="text" size="small" slot="reference">{{ scope.row.attachments.length || '-' }}</el-button>
          </el-popover>
          <el-button v-if="!scope.row.attachments" type="text" size="small" slot="reference">-</el-button>
        </template>
      </el-table-column>
      <el-table-column v-if="activeName == 2" prop="updateTime" label="回答时间"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button v-if="activeName == 1" type="text" size="small" @click="toDetail(scope.row)">录入答案</el-button>
          <el-button v-if="activeName == 2" type="text" size="small" @click="toEdit(scope.row)">修改答案</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
    <questionDialog :dialogVisible.sync="dialogVisible" :isEdit="isEdit" :currentId="currentId" @updateList="getList"> </questionDialog>
  </div>
</template>

<script>
import questionDialog from '../../../components/questionDialog.vue';
import { questionList, downloadUrl } from '@/api/api.js';
import dayjs from 'dayjs';
export default {
  components: {
    questionDialog
  },
  data() {
    return {
      dialogVisible: false,
      isEdit: false,
      expertName: '',
      applytDate: [],
      startDate: [],
      currentId: '',
      questionType: '',
      searchParams: {},
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      activeName: '1',

      tableData: [],
      options: [],
      questionTypeDic: null
    };
  },
  created() {},
  mounted() {
    this.getList();
    this.getDicList();
  },
  methods: {
    downLoadFile(url) {
      console.log(url);
      downloadUrl(url)
        .then((res) => {
          const headers = res.headers;
          console.log(res);
          console.log(headers);
          const contentType = headers['content-type'];
          const blob = new Blob([res.data], { type: contentType });

          const contentDisposition = res.headers['content-disposition'];
          let fileName = 'stock_record.xlsx';
          if (contentDisposition) {
            fileName = window.decodeURI(res.headers['content-disposition'].split('=')[1]);
          }
          console.log('文件名称：', fileName);

          // 非IE下载
          if ('download' in document.createElement('a')) {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob); // 创建下载的链接
            link.download = fileName; // 下载后文件名
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click(); // 点击下载
            window.URL.revokeObjectURL(link.href); // 释放掉blob对象
            document.body.removeChild(link); // 下载完成移除元素
          } else {
            // IE10+下载
            window.navigator.msSaveBlob(blob, fileName);
          }
          return;
        })
        .catch((e) => {
          this.$message({ message: '下载失败', type: 'error' });
        });
    },
    toDetail(item) {
      this.currentId = item.id;
      this.isEdit = false;
      this.dialogVisible = true;
    },
    toEdit(item) {
      this.currentId = item.id;
      this.isEdit = true;
      this.dialogVisible = true;
    },
    async getDicList() {
      this.options = await this.$store.dispatch('getDicOptionsList', 'question.type');
      this.questionTypeDic = await this.$store.dispatch('getDicAll');
    },
    getQuestionType(key) {
      return this.questionTypeDic['question.type'][key];
    },
    onCellClick(row) {
      // this.$router.push({ name: 'detailService', query: { id: row.id, from: 'metting' } })
    },
    search() {
      this.searchParams = {
        expertName: this.expertName,
        questionType: this.questionType,
        questionDateMin: this.applytDate && this.applytDate[0] ? dayjs(this.applytDate[0]).format('YYYY-MM-DD HH:mm:ss') : '',
        questionDateMax: this.applytDate && this.applytDate[1] ? dayjs(this.applytDate[1]).format('YYYY-MM-DD HH:mm:ss') : ''
      };
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    toEdit(item) {
      this.currentId = item.id;
      this.isEdit = true;
      this.dialogVisible = true;
    },
    handleClick(row) {
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    getList() {
      questionList({
        state: this.activeName,
        pageNum: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
        ..._.pickBy({ ...this.searchParams })
      })
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    },
    confirmApply(e) {
      console.log(e);
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span1 {
    padding-right: 24px;
    font-size: inherit;
    font-size: 12px;
  }
}
</style>
