<!-- //科技管理 -->
<template>
  <div>
    <el-row>
      <el-col :span="6" class="flex-row">
        <span class="span1">种植季名称</span>
        <el-input v-model="searchParams.name" style="width: 60%" placeholder="请输入内容" clearable></el-input>
      </el-col>
      <el-col :span="6" class="flex-row">
        <div></div>
      </el-col>
      <el-col :span="6" class="flex-row">
        <el-button type="primary" @click="search">搜索</el-button>
      </el-col>
    </el-row>
    <div style="height: 24px"></div>
    <el-table :data="tableData" stripe style="width: 100%" header-cell-class-name="header-cell-bg" header-row-class-name="header-bg">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <el-table-column fixed prop="name" label="种植方案名称"> </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          {{ geetStatus(scope.row.state) }}
        </template>
      </el-table-column>
      <el-table-column prop="descriptions" label="描述" show-overflow-tooltip> </el-table-column>
      <el-table-column label="种植作物">
        <template slot-scope="scope">
          {{ getCategoryDetail(scope.row.cropsACategoryId) }}
        </template>
      </el-table-column>
      <el-table-column label="关联种植季">
        <template slot-scope="scope">
          {{ getSeasonDetail(scope.row.seasonId) }}
        </template>
      </el-table-column>
      <el-table-column prop="schemeLandNums" label="关联地块">
        <template slot-scope="scope">
          {{ scope.row.schemeLandNums || '-' }}
          <!-- <el-button v-if="scope.row.state == 2" type="text" size="small" @click="toLand(scope.row)">编辑</el-button> -->
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button v-if="scope.row.state == 2" type="text" size="small" @click="toLand(scope.row)">关联地块</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
    <createPlanting :currentItem="currentItem" :dialogVisible.sync="dialogVisible" :isEdit="isEdit" :isDisable="isDisable" @updateList="getList">
    </createPlanting>
  </div>
</template>

<script>
import createPlanting from '../../../components/createPlanting.vue';
import { plantingList1, unpublishedPlanting, publishPlanting, seasonList, categoryList, getRequest } from '@/api/api.js';
import dayjs from 'dayjs';
export default {
  components: {
    createPlanting
  },
  data() {
    return {
      dialogVisible: false,
      isEdit: false,
      isDisable: false,
      title: '',
      applytDate: [],
      startDate: [],
      currentId: '',
      contractName: '',
      searchParams: {
        state: '2',
        name: ''
      },
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },

      tableData: [],
      season: null,
      category: null,
      currentItem: null
    };
  },
  created() {},
  mounted() {
    this.getList();
    this.getSeason();
    this.getCategoryList();
  },
  methods: {
    geetStatus(state) {
      // 1待启用、2可用、3不可用、4已删除
      let val = '';
      switch (state) {
        case 1:
          val = '待启用';
          break;
        case 2:
          val = '可用';
          break;
        case 3:
          val = '不可用';
          break;
        case 4:
          val = '已删除';
          break;
        default:
          break;
      }
      return val;
    },
    getSeasonDetail(key) {
      let val = '';
      this.season.forEach((e) => {
        //console.log(e);
        if (e.id == key) {
          //console.log(e.name);
          val = e.name;
        }
      });
      return val;
    },
    getCategoryDetail(key) {
      let val = '';
      this.category &&
        this.category.forEach((e) => {
          //console.log(e);
          if (e.id == key) {
            val = e.name;
          }
        });
      return val;
    },
    getSeason() {
      seasonList()
        .then((res) => {
          this.season = res;
        })
        .catch((error) => {});
    },

    getCategoryList() {
      this.options1 = [];
      categoryList().then((res) => {
        this.category = res;
      });
    },
    toDetail(item) {
      this.currentId = item.id;
      this.isEdit = false;
      (this.isDisable = true), (this.dialogVisible = true);
    },
    toFarming(item) {
      this.$router.push({ name: 'farming', query: { id: item.id } });
    },
    toLand(item) {
      this.$router.push({ name: 'land', query: { id: item.id } });
    },

    search() {
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    toEdit(item) {
      this.currentItem = item;
      this.isEdit = true;
      (this.isDisable = false), (this.dialogVisible = true);
    },
    publish(item) {
      let formData = new FormData();
      formData.append('id', item.id);
      publishPlanting(formData)
        .then((res) => {
          this.$message({
            message: '发布成功',
            type: 'success'
          });
          this.pageInfo.currentPage = 1;
          this.getList();
        })
        .catch((error) => {});
    },
    unpublished(item) {
      let formData = new FormData();
      formData.append('id', item.id);
      unpublishedPlanting(formData)
        .then((res) => {
          this.$message({
            message: '取消发布成功',
            type: 'success'
          });
          this.pageInfo.currentPage = 1;
          this.getList();
        })
        .catch((error) => {});
    },
    handleClick(row) {
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    getList() {
      getRequest('/api/operate/planting/use/scheme/page', {
        pageNum: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
        ..._.pickBy({ ...this.searchParams })
      })
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    },
    confirmApply(e) {
      //console.log(e);
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span1 {
    padding-right: 24px;
    font-size: inherit;
    font-size: 12px;
  }
}
</style>
